<template>
  <CRow>
    <CCol sm="6" md="4">
      <CCard>
        <CCardHeader>
          订阅1
        </CCardHeader>
        <CCardBody>
          <CDataTable
            striped
            small
            fixed
            :items="visibleData"
            :fields="fields"
          />
        </CCardBody>
        <CCardFooter>
          <CButton color="primary" @click="goBack">Back</CButton>
        </CCardFooter>
      </CCard>
    </CCol>
     
  </CRow>
</template>

<script>
export default {
  name: 'UserMember'
}
</script>
